const locale = {
  "en": {
    Header: "<span>Welcome to </span> Screen Repair Service",
    SubHeader: "For an uninterrupted experience, please <span class='ais_greenish' role='button' data-toggle='modal' href='#AisScreenRepairModal'>check that you are eligible to access this Screen Repair Service</span> and please have ready:<br><br>• Your <b>Identification/Passport/Work Permit Number</b><br>• Device make, model and <b>IMEI</b>",
    InputLabel: "Please enter your registered mobile number",
    CaptchaPlaceholder: "Type in the characters",
    Disclaimer: "Any personal information used or collected in this webchat is used or collected to provide you with the Screen Repair Services and is done so in accordance with AIS’s privacy policy.",
    PaymentDisclaimer: "Any payment made to Asurion (Thailand) Limited through this website will be transacted through NEW Asurion Singapore Pte Ltd.",
    SubmitBtnText: "Let's Begin",
    Copyright: "© 2018 Advanced Info Service PLC. All Rights Reserved",
    CopyrightLink: "What is Screen Repair?",
    PrivacyPolicy: "Privacy Policy",
    TermsAndCondition: "Terms and Conditions",
    TermsOfUse: "Website Terms",
    mobileInvalid: "Your mobile number does not match.",
    mobileInvalidAttemptExceed: "We regret to inform you that the mobile number you've provided doesn't match the details on our record.",
    ScreenRepairInfo: "• Access to this screen repair service is limited to customers of AIS Mobile Care (excluding customers of AIS Mobile Care Swap Unlimited).<br><br>• Enrollment with AIS Mobile Care will be verified by reference to National Identification or Passport, and the IMEI of device.<br><br>• AIS Mobile Care is a separate service to this Sceen Repair Service.",
    LabelScreenRepair: "Screen Repair Service",
    maintenanceText: 'Dear customers, We will be performing our scheduled maintenance on 23rd May 2021 from 9pm to 6am. During this time, our online service request and enquiry support services will not be available. We apologize for any inconvenience caused.',
    DownTimeNotice: {
      Title: "ANNOUNCEMENT",
      Body: "Dear valued customer,<br/><br/>Our system will undergo scheduled maintenance on <strong>28 October 2024 from 11:00 PM to 2:00 AM</strong>. During this time, the service request portal may be temporarily unavailable.<br/><br/>Thank you for your patience and understanding.",
      From: "from",
      To: "to"
    },
  },
  "th": {
    Header: "<span>ยินดีต้อนรับสู่ </span> บริการซ่อมหน้าจอ",
    SubHeader: "เพื่อความสะดวกในการรับบริการ โปรด <span class='ais_greenish' role='button' data-toggle='modal' href='#AisScreenRepairModal'>ตรวจสอบสิทธิ์ในการรับบริการซ่อมหน้าจอของท่าน</span> และโปรดเตรียมข้อมูลดังนี้<br><br>• <b>หมายเลขประจำตัวประชาชน/หนังสือเดินทาง/ใบอนุญาตทำงาน</b><br>• ยี่ห้อและรุ่นของอุปกรณ์  และหมายเลขประจำเครื่องโทรศัพท์เคลื่อนที่หรือ <b>IMEI</b>",
    InputLabel: "กรอกหมายเลขโทรศัพท์เคลื่อนที่ของท่านที่ได้ลงทะเบียนไว้",
    CaptchaPlaceholder: "ใส่ตัวเลขสี่หลักทางด้านขวามือ",
    Disclaimer: "ข้อมูลส่วนบุคคลใด ๆ ของท่านที่ถูกใช้หรือถูกเก็บรวบรวมในเว็บแชทนี้ ได้ถูกใช้หรือถูกเก็บรวบรวม เพื่อให้บริการเปลี่ยนหน้าจอแก่ท่าน และเป็นไปตามนโยบายความเป็นส่วนตัวของเอไอเอส",
    PaymentDisclaimer: "การชำระเงินใดๆ ที่ทำกับบริษัท Asurion (Thailand) Limited ผ่านทางเว็บไซต์นี้ จะทำธุรกรรมผ่าน NEW Asurion Singapore Pte Ltd.",
    SubmitBtnText: "เริ่มทำรายการ",
    Copyright: "© 2018 บริษัท แอดวานซ์ อินโฟร์ เซอร์วิส จำกัด (มหาชน). สงวนลิขสิทธิ์",
    CopyrightLink: "บริการซ่อมหน้าจอคืออะไร",
    PrivacyPolicy: "นโยบายความเป็นส่วนตัว",
    TermsAndCondition: "เงื่อนไขและข้อตกลง",
    TermsOfUse: "ข้อกำหนดการใช้งานเว็บไซต์",
    mobileInvalid: "ไม่พบหมายเลขนี้ในระบบ",
    mobileInvalidAttemptExceed: "ขออภัยค่ะ หมายเลขนี้ไม่พบในระบบ",
    ScreenRepairInfo: "• บริการซ่อมหน้าจอนี้จะจำกัดเฉพาะลูกค้าของ AIS Mobile Care เท่านั้น (ไม่รวมลูกค้าของ AIS Mobile Care Swap Unlimited)<br><br>• จะมีการตรวจสอบสิทธิ์ของลูกค้าเอไอเอสโมบายแคร์ด้วยหมายเลขบัตรประชาชน/หนังสือเดินทาง/ใบอนุญาตทำงาน และหมายเลข IMEI/อีมี่ ของโทรศัพท์<br><br>• บริการซ่อมหน้าจอนี้เป็นบริการที่แยกต่างหากจากเอไอเอสโมบายแคร์",
    LabelScreenRepair: "การบริการซ่อมหน้าจอ",
    maintenanceText: 'แจ้งปิดปรับปรุงระบบ AIS Screen Repair ชั่วคราวในวันที่ 23 พฤษภาคม 2564 ตั้งแต่เวลา 21.00 น. ถึง 06.00 น. ขออภัยในความไม่สะดวกมา ณ ที่นี้',
    DownTimeNotice: {
      Title: "ประกาศ",
      Body: "เรียน ลูกค้าผู้มีอุปการคุณทุกท่าน<br/><br/>ทางบริษัทขอแจ้งให้ลูกค้าทุกท่านทราบว่า ทางบริษัทจะมีการปรับปรุงและอัพเดทระบบเป็นการชั่วคราว โดยจะเริ่มดำเนินการในวันที่ <strong>28 ตุลาคม 2024 ตั้งแต่เวลา 23.00 น. - 2.00 น.</strong> ซึ่งจะส่งผลให้ ช่วงเวลาดังกล่าว ลูกค้าจะไม่สามารถเข้าใช้บริการในเว็บไซต์ได้<br/><br/>จึงขออภัยในความไม่สะดวก",
      From: "ตั้งแต่เวลา",
      To: "-"
    }
  }
}

export default locale;